<template>
	<div>
		<VFileManger
			v-model="fileManager"
			@image-selected="setImage"
		/>
		<editor
			v-model="localeValue"
			ref="tinyMCE"
			:id="editorId"
			api-key="76xbclk6ft4lxzhex7or5smgx23381bj94berghpfi44m5cy"
			:directionality="$i18n.locale === 'en' ? 'rtl' : 'ltr'"
			path_absolute="/"
			:init="inits"
			:inline="inline"
			:class="{'validate-color': error}"
			:disabled="disabled"
			output-format="html"
		/>
		<v-progress-linear v-if="loading" indeterminate class="editor-progress"/>
	</div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { customStyles } from '@/helpers/tinymce-plugins'
// import VFileManger from '@/components/VLegacyFileManger.vue'
import VFileManger from '@/components/file-manager/VFileManagerDialog.vue'

export default {
	name: 'TextEditor',

	props: {
		'editor-id': { type: String, default: 'editor' },
		height: { type: Number, default: 450 },
		'mini-tools': { type: Boolean, default: false },
		'hide-menubar': { type: Boolean, default: false },
		'floating-toolbar': { type: Boolean, default: false },
		inline: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		'use-template-builder': { type: Boolean, default: false },
		error: { type: Boolean, default: false },
		value: { type: String }
	},

	data() {
		return {
			useLegacy: false,
			fileManager: null,
			imagePickerCallBack: null,
		}
	},

	components: {
		Editor,
		VFileManger
	},
	
	computed: {
		localeValue: {
			get() {
				return this.value;
			},
			set(newValue) {
				this.$emit('input', newValue)
			}
		},

		inits() {
			return {
				height: this.height,
				// content_css: 'gray',
				// skin: 'gray',
				skin_url: '../css/tinyMCE/skins/ui/gray',
				// image_uploadtab: false,
				// images_upload_credentials: true,
				image_title: true,
				automatic_uploads: true,
				file_picker_types: 'image',
				statusbar: false,
				valid_children: this.useTemplateBuilder ? '+body[style]' : '',
				/* valid_elements: 'style',
				extended_valid_elements: 'style,link[href|rel]',
				custom_elements: 'style,link,~link', */
				file_browser_callback: function(fieldName, url, type, win) {
					// const x = window.innerWidth || document.documentElement.clientWidth || document.querySelector('body').clientWidth;
					// const y = window.innerHeight || document.documentElement.clientHeight || document.querySelector('body').clientHeight;

					// let cmsURL = BASE_URL + '/file-manager/all?field_name=' + fieldName;
					// if (type === 'image') {
					// 	cmsURL = cmsURL + '&type=Images';
					// } else {
					// 	cmsURL = cmsURL + '&type=Files';
					// }

					// // FIXME
					// window.tinymce.activeEditor.windowManager.open({
					// 	file: cmsURL,
					// 	title: 'Filemanager',
					// 	width: x * 0.8,
					// 	height: y * 0.8,
					// 	resizable: 'yes',
					// 	close_previous: 'no'
					// });
					win.document.forms[0].elements[fieldName].value = 'my browser value';
				},
				file_picker_callback: (callback, value, meta) => {
					/*
						const input = document.createElement('input');
						input.setAttribute('type', 'file');
						input.setAttribute('accept', 'image/*');
						input.onchange = function () {
							const file = this.files[0];

							const reader = new FileReader();
							// reader.onload = function (reader,cd) {
							reader.onload = function () {
								const id = 'blobid' + (new Date()).getTime();
								const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
								const base64 = reader.result.split(',')[1];
								const blobInfo = blobCache.create(id, file, base64);
								blobCache.add(blobInfo);

								callback(blobInfo.blobUri(), { title: file.name });
							};
							reader.readAsDataURL(file);
						};
						input.click();
					*/
					
					if (this.useLegacy) {
						this.fileManager = true;
					} else {
						this.$eventBus.$emit('file-manager', { images: true }, this.setImage);
					}
					this.imagePickerCallBack = callback;
				},
				setup: (editor) => {
					customStyles(editor, 'customStyle');

					// adding templateBuilder button
					if (this.useTemplateBuilder) {
						editor.ui.registry.addIcon('palette-advanced', '<i class="mdi mdi-palette-advanced" style="font-size: 24px"></i>');
						editor.ui.registry.addButton('templateBuilder', {
							icon: 'palette-advanced',
							tooltip: 'template builder',
							onAction: () => {
								this.$eventBus.$emit('template-builder', this.value, (codes, htmlCss) => {
									this.localeValue = codes;
								});
							}
						});
					}

					// events
					editor.on('keypress', e => this.$emit('keypress', e));
					editor.on('keyup', e => this.$emit('keyup', e));
					editor.on('keydown', e => this.$emit('keydown', e));
				},
				menubar: !this.hideMenubar ? 'file edit insert view format tools help' : '',
				plugins: [
					'advlist autolink lists link image charmap print preview hr anchor pagebreak',
					'searchreplace wordcount visualblocks visualchars code  help fullscreen',
					'insertdatetime media save table contextmenu directionality',
					'paste textcolor colorpicker textpattern',
					'emoticons imagetools codesample quickbars toc'
					// 'autoresize fullpage'
				],
				toolbar:
					!this.miniTools
						? `
							newdocument selectall | undo redo | styleselect | fontselect fontsizeselect | bold italic underline strikethrough removeformat | permanentpen formatpainter | hr |
							alignleft aligncenter alignright alignjustify | 
							image link media | 
							bullist numlist outdent indent |
							ltr rtl |
							forecolor backcolor customStyle templateBuilder | 
							searchreplace wordcount | 
							preview fullscreen | code
						`
						: `
							bold italic underline link | alignleft aligncenter alignright alignjustify bullist numlist |
							ltr rtl |
							forecolor backcolor
						`,
				resize: true,
				toolbar_mode: this.floatingToolbar ? 'floating' : 'wrap',
				// toolbar_mode: 'floating',
				relative_urls: false,
				remove_script_host: false,
				// imagetools_toolbar: 'rotateleft rotateright flipv fliph editimage imageoptions | customStyle',
				imagetools_toolbar: 'rotateleft rotateright flipv fliph imageoptions | customStyle',
				quickbars_selection_toolbar: 'bold italic underline | h1 h2 h3 | quicklink blockquote | forecolor backcolor customStyle',
				language: this.$i18n.locale
			}
		}
	},

	methods: {
		setThumbnail(imageUrl) {
			// remove thumbnail image if it already exists
			const thumbnailImage = document.getElementById('tinymce-thumbnail-image');
			if (thumbnailImage) thumbnailImage.remove();

			// insert thumbnail image
			const dialogContent = document.querySelector('.tox-dialog__body-content .tox-form__group div');
			dialogContent.insertAdjacentHTML(
				'beforeend',
				`<div
					id="tinymce-thumbnail-image"
					style="
						width: 70px; 
						height: 56px; 
						border-radius: 11px;
						background: center / cover no-repeat url(${imageUrl});
						border: 1px solid #ddd;
					"
				></div>`.replace(/[\n\t]/g, (matched) => ({ '\n': ' ', '\t': '' })[matched])
			)
		},

		setImage(imageUrl) {
			this.fileManager = false;
			const splittedUrl = imageUrl.split('/');
			const imageName = splittedUrl[splittedUrl.length - 1];
			this.imagePickerCallBack(imageUrl, { title: imageName });
			this.setThumbnail(imageUrl);
		},

		/* getTagsAndStyles(value) {
			let val = value;
			if (val) {
				const matched = (value || '').match(/<style>[.\s\S]*?<\/style>/g);
				if (matched && this.$refs.tinyMCE) {
					this.clearStyles();
					matched.forEach(style => {
						this.addCssStyle(style.slice(7, -8));
					});
					val = val.replace(/<style>[.\s\S]*?<\/style>/g, '');
				}
			}
			return val;
		},

		addCssStyle(cssStyles) {
			this.$refs.tinyMCE.editor.dom.addStyle(cssStyles);
		},
		getCssStyles(withStyleTag = true) {
			let cssStyles = '';
			const styleSheet = this.$refs.tinyMCE.editor.dom.doc.styleSheets[0];
			if (styleSheet.href) return '';
			
			for (let i = 0; i < styleSheet.cssRules.length; i++) {
				cssStyles += (i ? ' ' : '') + styleSheet.cssRules[i].cssText;
			}
			return withStyleTag ? `<style>${cssStyles}</style>` : cssStyles;

			/*
			window.tinyMCE.activeEditor.dom.addStyle(this.editor.getCss());
			window.tinyMCE.activeEditor.setContent(value, { format: 'raw' });
			content_style: 'body { font-family: Arial; }'
			window.tinyMCE.activeEditor.contentStyles = css;
			
			const styleTag = window.tinyMCE.activeEditor.$('head')[0].querySelector('#mceDefaultStyles');
			if (styleTag) styleTag.innerText = '';
			/
		},
		clearStyles() {
			const styleSheet = this.$refs.tinyMCE.editor.dom.doc.styleSheets[0];
			if (!styleSheet.href && styleSheet.cssRules.length) styleSheet.ownerNode.innerText = '';
		} */
	}
}
</script>

<style lang="scss" scoped>
@import "../css/tinyMCE/skins/ui/gray/skin.min.css";
.editor-progress {
	width: 99%;
	margin: 0 auto;
	margin-top: -5px;
}
</style>
<style>
.validate-color + .tox.tox-tinymce{
	border: 1px solid red;
}
</style>